.ReactTable {
  border-top: 2px solid rgba(15, 50, 70, 0.15);
  font-size: 1.4rem; }
  .ReactTable.clickable .rt-tr {
    cursor: pointer; }
  .ReactTable .actions-right {
    text-align: right; }
  .ReactTable .actions-left {
    text-align: left; }
  .ReactTable .actions-center {
    text-align: center; }
  .ReactTable .rt-thead .rt-th {
    color: rgba(15, 50, 70, 0.65);
    font-weight: 300;
    line-height: 4rem;
    margin: auto;
    position: relative;
    text-align: left;
    text-transform: uppercase; }
    .ReactTable .rt-thead .rt-th:first-child {
      padding-left: 24px; }
    .ReactTable .rt-thead .rt-th:last-child {
      padding-right: 24px; }
    .ReactTable .rt-thead .rt-th.centered {
      text-align: center; }
  .ReactTable .rt-thead.-header {
    box-shadow: none; }
  .ReactTable .rt-thead.-filters input:focus {
    border-bottom: 2px solid #50bcb5;
    box-shadow: none;
    outline: none;
    transition-duration: 0.3s; }
  .ReactTable .rt-tr .rt-td {
    margin: auto; }
    .ReactTable .rt-tr .rt-td:first-child {
      padding-left: 24px; }
    .ReactTable .rt-tr .rt-td:last-child {
      padding-right: 24px; }
    .ReactTable .rt-tr .rt-td.centered {
      text-align: center; }
  .ReactTable .-pagination {
    background-color: transparent;
    box-shadow: none;
    color: #50bcb5;
    font-family: YogaSansMedium, Lucida Grande, Lucida Sans Unicode, Lucida Sans, Helvetica, Arial, sans-serif;
    font-size: 1.6rem;
    font-weight: 400; }
    .ReactTable .-pagination input {
      background: none;
      border: 0;
      border-bottom: 1px solid #0f3246;
      border-radius: 0;
      color: #0f3246; }
      .ReactTable .-pagination input:focus {
        border-bottom: 2px solid #50bcb5;
        box-shadow: none;
        outline: none;
        transition-duration: 0.3s; }
    .ReactTable .-pagination .-btn {
      background-color: transparent;
      color: #50bcb5;
      font-family: YogaSansMedium, Lucida Grande, Lucida Sans Unicode, Lucida Sans, Helvetica, Arial, sans-serif; }
      .ReactTable .-pagination .-btn:hover {
        background-color: rgba(80, 188, 181, 0.08); }
        .ReactTable .-pagination .-btn:hover:not([disabled]) {
          background: rgba(80, 188, 181, 0.08);
          color: #50bcb5; }

.rechart-wrapper,
.recharts-text {
  font-family: 'YogaSansMedium';
  color: #0f3246;
  fill: #0f3246; }

.recharts-cartesian-axis-tick-value {
  fill: rgba(15, 50, 70, 0.65); }

.recharts-cartesian-axis-tick {
  fill: #0f3246; }

.statementLabel {
  font: 14px YogaSansMedium; }

html {
  font-size: 10px; }

body {
  color: #0f3246;
  font-family: YogaSansMedium, Lucida Grande, Lucida Sans Unicode, Lucida Sans, Helvetica, Arial, sans-serif;
  font-variant-numeric: lining-nums;
  font-weight: 400;
  margin: 0; }

a {
  color: #50bcb5;
  text-decoration: none; }

@font-face {
  font-family: 'YogaSansMedium';
  src: url("../fonts/5666373/8306baad-30f2-424a-aac1-20f4e6916d78.eot?#iefix");
  src: url("../fonts/5666373/8306baad-30f2-424a-aac1-20f4e6916d78.eot?#iefix") format("eot"), url("../fonts/5666373/2178d79e-cd2b-4b9e-a178-6941c8255bf3.woff2") format("woff2"), url("../fonts/5666373/e90f8240-0e06-468a-ada2-50c402cd28ad.woff") format("woff"), url("../fonts/5666373/3b8eb9c4-50b4-4b1f-a4e9-9801546474de.ttf") format("truetype"); }
